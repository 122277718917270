import {o_shoppages} from './shoppages.js';
import {eventQBus} from '@otto-ec/global-resources/event-q-bus';
import {eventLoader} from '@otto-ec/global-resources/event-loader';

function initEventHandler () {
  if (!window.shoppagesAccordionInitialized) {
    window.shoppagesAccordionInitialized = true;
    window.addEventListener('hashchange', openByHash);
  }
}
function openByHash () {
  const hash = window.location.hash;
  if (hash === null || !hash.match(/^#(\w+)/)) {
    return false;
  }
  const element = document.getElementById(RegExp.$1);
  if (element === null) {
    return false;
  }
  if (element.classList.contains('js_shoppages_accordion') &&
    element.classList.contains('pl_accordion__header') && element.parentElement !== null) {
    element.classList.add('pl_accordion__header--open');
    return true;
  }

  return false;
}

o_shoppages.accordion = {

  handlePageLoad (): void {
    if (!openByHash()) {
      const accordion_header = document.querySelector('.js_shoppages_accordion.pl_accordion__header');
      if (accordion_header !== null) {
        accordion_header.classList.add('pl_accordion__header--open');
      }
    }
  },

  init (): void {
    this.handlePageLoad();
    initEventHandler();
    /*                                                  */
    eventQBus.emit('assets.accordion.init', '.shoppagesLayer');
    /*                                                   */
    eventQBus.emit('assets.accordion.init', '.shoppagesSheet');
  }

};

eventLoader.onLoad(100, () => {
  o_shoppages.accordion.init();
});
