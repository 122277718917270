import {o_shoppages} from './shoppages.js';

o_shoppages.focussedDialog = {
  init (): void {
    window.o_global.eventQBus.emit(
      'assets.focusseddialog.init',
      '',
      location.href
    );
  }
}
