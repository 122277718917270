import {o_shoppages} from './shoppages.js';
import {dom} from '@otto-ec/global-resources/dom';
import {eventLoader} from '@otto-ec/global-resources/event-loader';
import { submitEvent } from '@otto-ec/tracking-bct';

const JS_CLICK_TRACKING_CLASS = 'js_contentElementClickTracking';
const JS_CONTENT_ELEMENT_ROOT_CLASS = 'sp_js_contentElement';
const JS_VIEW_TRACKING_CLASS = 'js_contentElementViewTracking';
const DATA_ELEMENT_TYPE_KEY = 'data-content-element-type';
const DATA_ELEMENT_NAME_KEY = 'data-content-element-name';

function decodeUrlQueryParameterValue (value: string): string {
  try {
    return decodeURIComponent(value.replace(/\+/g, '%20'));
    /*                                                         */
  } catch (error) {
    return value;
  }
}

export interface AccordionChangeEvent {
  detail: {
    isOpen: boolean;
  };
  target: Element;
}

o_shoppages.o_tracking = {

  initClickHandlerForLinks ():void {
    window.o_util.event.delegate(
      document,
      'click',
      `.${JS_CONTENT_ELEMENT_ROOT_CLASS} a:not(.js_expanderButton), a.${JS_CONTENT_ELEMENT_ROOT_CLASS}`,
      (event:MouseEvent):void => {
        const targetElement:Element = event.target as Element;
        if (targetElement) {
          let el = null;
          if (targetElement.tagName === 'A') {
            el = event.target;
          } else {
            el = dom.getParentByClassName(targetElement, JS_CLICK_TRACKING_CLASS);
          }
          if (el !== null) {
            const contentElementRoot:Element|null = dom.getParentByClassName(
              targetElement,
              JS_CONTENT_ELEMENT_ROOT_CLASS
            );
            if (contentElementRoot) {
              const contentElementType:string|null = contentElementRoot.getAttribute(DATA_ELEMENT_TYPE_KEY);
              const contentElementName: string = contentElementRoot.getAttribute(DATA_ELEMENT_NAME_KEY) || '';
              o_shoppages.o_tracking.sendEventToTrackingServer(
                {
                  ot_ContentElementActivity: 'click',
                  ot_ContentElementTyp: contentElementType,
                  ot_ContentElementName: decodeUrlQueryParameterValue(contentElementName)
                });
            }
          }
        }
      });
  },

  initViewHandlerForContentElements ():void {
    const elements = document.getElementsByClassName(JS_VIEW_TRACKING_CLASS);
    if (elements !== null) {
      Array.prototype.forEach.call(elements, (element):void => {
        if (!element.getAttribute('data-content-element-tracked')) {
          const contentElementType = element.getAttribute(DATA_ELEMENT_TYPE_KEY);
          const contentElementName = element.getAttribute(DATA_ELEMENT_NAME_KEY);
          o_shoppages.o_tracking.sendEventToTrackingServer(
            {
              ot_ContentElementActivity: 'view',
              ot_ContentElementTyp: contentElementType,
              ot_ContentElementName: decodeUrlQueryParameterValue(contentElementName)
            });
          element.setAttribute('data-content-element-tracked', true);
        }
      });
    }
  },

  initClickHandlerForAccordions ():void {
    const elements = document.getElementsByClassName('js_shoppages_accordion');
    if (elements !== null) {
      Array.prototype.forEach.call(elements, (element):void => {
        if (!element.getAttribute('data-accordion-initialized')) {
          element.addEventListener('accordionChange', (event: AccordionChangeEvent): void => {
            if (event.detail.isOpen) {
              const accordionContentElement:Element|null = event.target.nextElementSibling;
              if (accordionContentElement) {
                const contentElementType:string|null = accordionContentElement.getAttribute(DATA_ELEMENT_TYPE_KEY);
                const contentElementName:string = accordionContentElement.getAttribute(DATA_ELEMENT_NAME_KEY) || '';
                o_shoppages.o_tracking.sendEventToTrackingServer(
                  {
                    ot_ContentElementActivity: 'expand',
                    ot_ContentElementTyp: contentElementType,
                    ot_ContentElementName: decodeUrlQueryParameterValue(contentElementName)
                  });
              }
            }
          });
          element.setAttribute('data-accordion-initialized', true);
        }
      });
    }
  },

  sendEventToTrackingServer (trackingData: object):void {
    submitEvent(trackingData);
  },

  init ():void {
    if (!window.shoppagesTrackingWrapperInitialized) {
      window.shoppagesTrackingWrapperInitialized = true;
      this.initClickHandlerForLinks();
    }
    this.initViewHandlerForContentElements();
    this.initClickHandlerForAccordions();
  }
};

eventLoader.onLoad(100, () => {
  o_shoppages.o_tracking.init();
});

