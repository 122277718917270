import {o_shoppages} from './shoppages.js';
import {eventLoader} from '@otto-ec/global-resources/event-loader';

export type CliplisterViewer = {
  onReady(param: () => void): void;
  onPlay(param: () => void): void;
  onProgress10(param: () => void): void;
  onProgress50(param: () => void): void;
  onProgress90(param: () => void): void;
  onQualityChange(param: () => void): void;
  getAssets(): [
    {
      duration: number;
    }
  ];
  getCurrentAssetIndex(): number;
  getCurrentQuality(): { display: string };
};

export type CliplisterViewerClass = {
  new(playerConfig: Record<string, unknown>): CliplisterViewer;
};

function getPlayerConfig(requestKey: string, autoplay: boolean, cliplisterElementId: string) {
  return {
    parentId: cliplisterElementId,
    customer: 86605,
    assetKeys: [requestKey],
    keyType: 10000,
    backgroundColor: '#000000',
    autoplay,
    defaultQuality: 'auto',
    assetLimit: 1,
    stage: {
      width: '100%',
      aspectRatio: 'asset'
    },
    plugins: {
      ClickableVideo: {
        layer: 1
      },
      InnerControls: {
        breakpoints: {
          s: 447 /*                            */
        },
        layer: 2,
        id: 'controls',
        blacklist: ['playback-speed', 'share'],
        template: {
          type: 'external',
          source: 'https:/*                                                                                           */
        }
      },
      BufferingSpinner: {
        layer: 3
      },
      PreviewImage: {
        layer: 4
      },
      PlayButton: {
        id: 'playButton',
        layer: 5,
        image: 'https:/*                                                                                        */
        width: 72,
        height: 72
      }
    }
  };
}

function enrichElementWithRandomId(element: Element): string {
  const randomString = Math.random().toString(36)
    .substring(7);
  element.setAttribute('id', randomString);
  return randomString;
}

function prepareExternalCliplisterStuff(callWhenExternalCliplisterIsAdded: (event: any) => void): void {
  const script: HTMLScriptElement = document.createElement('script');
  script.type = 'text/javascript';
  /*                                                                                                   */
  script.onreadystatechange = callWhenExternalCliplisterIsAdded;
  script.onload = callWhenExternalCliplisterIsAdded;
  script.src = 'https:/*                                                                                                                                                                                                                                                    */

  const elements = document.getElementsByTagName('head');
  if (elements !== null && elements.length > 0) {
    elements[0].appendChild(script);
  }
}

function createPlayer(cliplisterVideo: Element): CliplisterViewer | null {
  if (cliplisterVideo.getAttribute('id')) {
    return null;
  }
  const cliplisterVideoId: string = enrichElementWithRandomId(cliplisterVideo);
  const requestKey: string | null = cliplisterVideo.getAttribute('data-requestkey');
  if (requestKey === null) {
    return null;
  }
  const autoplay: boolean = cliplisterVideo.getAttribute('data-autoplay') === "true";
  trackVideoView(requestKey);
  const player: CliplisterViewer = new window.Cliplister.Viewer(getPlayerConfig(requestKey, autoplay, cliplisterVideoId));
  let videoLength: any = null;

  player.onReady((): void => {
    const assets = player.getAssets();
    const currentAssetIndex = player.getCurrentAssetIndex();
    const currentAsset = assets[currentAssetIndex];

    videoLength = currentAsset.duration;
  });

  player.onPlay((): void => {
    o_shoppages.cliplister.trackVideoStart(requestKey, videoLength);
  });

  player.onProgress10((): void => {
    o_shoppages.cliplister.trackVideoProgress(requestKey, videoLength, 0.1);
  });

  player.onProgress50((): void => {
    o_shoppages.cliplister.trackVideoProgress(requestKey, videoLength, 0.5);
  });

  player.onProgress90((): void => {
    o_shoppages.cliplister.trackVideoProgress(requestKey, videoLength, 0.9);
  });

  player.onQualityChange((): void => {
    const currentQuality = player.getCurrentQuality();
    o_shoppages.cliplister.trackVideoQuality(currentQuality.display);
  });

  return player;
}

function loadVideos(): void {
  const cliplistervideos = document.querySelectorAll('.cliplistervideo:not([id])');
  if (cliplistervideos && cliplistervideos.length > 0) {
    let done = false;
    const callWhenExternalCliplisterIsAdded = (event: any): void => {
      if (!done && (!event || !event.currentTarget || !event.currentTarget.readyState ||
        (/loaded|complete/).test(event.currentTarget.readyState))) {
        done = true;
        Array.prototype.forEach.call(cliplistervideos, o_shoppages.cliplister.createPlayer);
      }
    };
    o_shoppages.cliplister.prepareExternalCliplisterStuff(callWhenExternalCliplisterIsAdded);
  }
}

function initVideos(): void {
  eventLoader.onLoad(40, (): void => {
    loadVideos();
  });
}

function trackVideoView(requestkey: string): void {
  o_shoppages.cliplister.sendEventToTrackingServer({
    ot_Video: 'view',
    ot_VideoRequestKey: requestkey
  });
}

function trackVideoStart(requestKey: string, videoLength: any): void {
  o_shoppages.cliplister.sendEventToTrackingServer({
    ot_Video: 'start',
    ot_VideoRequestKey: requestKey,
    ot_VideoLength: videoLength
  });
}

function trackVideoProgress(requestkey: string, videoLength: any, progress: number): void {
  o_shoppages.cliplister.sendEventToTrackingServer({
    ot_Video: 'progress',
    ot_VideoRequestKey: requestkey,
    ot_VideoLength: videoLength,
    ot_VideoProgress: progress
  });
}

function trackVideoQuality(currentQuality: string): void {
  o_shoppages.cliplister.sendEventToTrackingServer({
    ot_VideoQuality: currentQuality
  });
}

function sendEventToTrackingServer(trackingData: object): void {
  o_shoppages.o_tracking.sendEventToTrackingServer(trackingData)
}

o_shoppages.cliplister = {
  initVideos,
  enrichElementWithRandomId,
  loadVideos,
  prepareExternalCliplisterStuff,
  createPlayer,
  trackVideoView,
  trackVideoStart,
  trackVideoProgress,
  trackVideoQuality,
  sendEventToTrackingServer
};
eventLoader.onLoad(100, (): void => {
  o_shoppages.cliplister.initVideos();
});
