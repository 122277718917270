import {o_shoppages} from './shoppages.js';
import {cookie} from '@otto-ec/global-resources/cookie';

export interface SmartAppBannerArgs {
  shouldDisplaySmartBanner: boolean;
  adjustLink: string;
}

const COOKIE_NAME = 'sp_smartAppBanner';
const COOKIE_DEFAULT_VALUE = '1';
const COOKIE_PATH = '/';
const COOKIE_DURATION = 14;

/**
 *
 *
 */
o_shoppages.smartAppBanner = {
  initSmartAppBanner ({shouldDisplaySmartBanner, adjustLink}:SmartAppBannerArgs): void {
    if (shouldDisplaySmartBanner === true) {
      const smartAppBannerCookie = cookie.get(COOKIE_NAME);
      if (typeof smartAppBannerCookie === 'undefined' || smartAppBannerCookie !== COOKIE_DEFAULT_VALUE) {
        const smartBanner = document.getElementById('sp_smartAppBanner');
        const closeButton = document.getElementById('sp_smartAppBannerClose');
        const gotoButton = document.getElementById('sp_smartAppBannerGotoAppButton') as HTMLAnchorElement;
        if (smartBanner !== null && closeButton !== null && gotoButton !== null) {
          closeButton.onclick = () => {
            o_shoppages.o_tracking.sendEventToTrackingServer({ot_AppBanner: 'closed'});
            cookie.set(COOKIE_NAME, COOKIE_DEFAULT_VALUE, {days: COOKIE_DURATION, path: COOKIE_PATH});
            smartBanner.style.display = 'none';
          };
          gotoButton.href = adjustLink;
          gotoButton.onclick = () => {
            o_shoppages.o_tracking.sendEventToTrackingServer({ot_AppBanner: 'clicked'});
          };

          o_shoppages.o_tracking.sendEventToTrackingServer({ot_AppBanner: 'shown'});
          smartBanner.style.display = 'block';
        }
      }
    }
  }
};
