import {o_shoppages} from './shoppages.js';
import {eventLoader} from '@otto-ec/global-resources/event-loader';
import {cookie} from '@otto-ec/global-resources/cookie';

export interface AdjustLinkData {
  linkTarget: string;
  adjustIosToken: string;
  adjustAndroidToken: string;
  adjustFallbackLink: string;
}

function initLink (adjustLinkElement: Element): void {
  if (adjustLinkElement.hasAttribute('data-app-link')) {
    const adjustLinkDataEncoded = adjustLinkElement.getAttribute('data-app-link') || '';
    try {
      const decodedAdjustLinkDataJsonString = window.atob(adjustLinkDataEncoded);
      const decodedAdjustLinkData = JSON.parse(decodedAdjustLinkDataJsonString);
      /*                                                         */
      if (decodedAdjustLinkData.linkTarget) {
        if (isApp()) {
          adjustLinkElement.setAttribute('href', buildApplinkUrl(decodedAdjustLinkData));
        } else {
          adjustLinkElement.setAttribute('href', buildAdjustLinkUrl(decodedAdjustLinkData));
        }
      }
    } catch (exception) {
      /*                                  */
      console.error(exception);
    } finally {
      adjustLinkElement.removeAttribute('data-app-link');
    }
  }
}
function buildAdjustLinkUrl (adjustLinkData: AdjustLinkData) {
  const encodedLinkTarget = encodeURIComponent(adjustLinkData.linkTarget);
  const hostName = resolveHostname();
  return `https:/*                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                        */
}
function buildApplinkUrl (adjustLinkData: AdjustLinkData) {
  return `https:/*                                                                                  */
}
function isApp () {
  return cookie.exists('app');
}
function resolveHostname () {
  return location.hostname;
}

o_shoppages.adjustLink = {
  initLinks (): void {
    const adjustLinkElements = document.querySelectorAll('[data-app-link]');
    Array.prototype.forEach.call(adjustLinkElements, initLink);
  }
};

eventLoader.onAllScriptsExecuted(100, () => {
  o_shoppages.adjustLink.initLinks();
});
